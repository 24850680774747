import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function warehouserentorderUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    // { key: 'warehouse_id', label: 'ID', sortable: true },
    { key: 'warehouse_name', label: '仓库', sortable: true },
    // { key: 'supplier_id', label: '供应商ID', sortable: true },
    { key: 'supplier_name', label: '供应商名称', sortable: true },
    { key: 'purchase_team_id', label: '销售权', sortable: true },
    // { key: 'product_id', label: '商品ID', sortable: true },
    { key: 'code', label: '商品69码', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'create_time', label: '添加时间', sortable: true },
    { key: 'total_amount', label: '总租金', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    // { key: 'creator', label: '添加人ID', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
    //     { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('order_id')
  const isSortDirDesc = ref(true)
  const condition = ref({
    // addTime:new Date().getFullYear()+"-"+(new Date().getMonth()+1)+"-"+new Date().getDate()
  })
  const totalAmount = ref('')
  const sumTotalAmount = ref('')
  const routeType = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey,routeType], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    if (routeType.value==0){
      routeType.value=config.type
    }
    store
      .dispatch('warehouserentorder/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        code:condition.value.code,
        name:condition.value.name,
        companyId:condition.value.companyId,
        warehouseId:condition.value.warehouseId,
        addTime:condition.value.addTime,
        type:routeType.value,
        stockId:condition.value.stockId,
        purchaseTeamId:isEmpty(condition.value.purchaseTeamId)?null:condition.value.purchaseTeamId.value,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        totalAmount.value = data.ext.totalAmount
        sumTotalAmount.value = data.ext.sumTotalAmount
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '仓库租金列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    condition,
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    totalAmount,
    routeType,
    sumTotalAmount,

    // Extra Filters
  }
}
