<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <div class="demo-spacing-0">
              <b-alert
                  variant="primary"
                  show
              >
                <div class="alert-body">
                  <span><strong>
                  总租金：{{sumTotalAmount}}
                  </strong></span>
                </div>
              </b-alert>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>

        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品69码:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.code"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="relational_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.name"
                      name="relational_no"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="stockId:"
                    label-for="stock_id"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.stockId"
                      name="stock_id"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="供应商"
                    type="input"
                    v-on:change="fromChildren($event,['companyName','companyId'])"
                    :params="['companyName','companyId']"
                    :value="condition.companyName"
                    modalName="供应商"
                    placeholder="点击选择供应商"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="仓库"
                    type="input"
                    v-on:change="fromChildren($event,['warehouseName','warehouseId'])"
                    :params="['warehouseName','warehouseId']"
                    :value="condition.warehouseName"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                >
                </xy-input-button>
              </b-col>

              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="创建日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['addTime'])"
                                 :params="['addTime']"
                                 :value="condition.addTime"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售权:"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="condition.purchaseTeamId"
                      name="status"
                      class="select-size-sm"
                      placeholder="请选择销售权"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="createExcel"
                >
                  <span class="align-right">导出</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="6" variant="primary">
              <span><strong>合计</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{totalAmount}}</strong></span>
            </b-td>
          </b-tr>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.item" :fields="fields">
                <template #cell(in_time)="data">
                  {{ toDate(data.item.in_time) }}
                </template>

                <template #cell(out_time)="data">
                  {{ isEmpty(data.item.out_time) ? '' : toDate(data.item.out_time) }}
                </template>

              </b-table>
            </div>
          </b-card>
        </template>

        <!-- Column: Type -->
        <template #cell(warehouse_name)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          {{ data.item.warehouse_name }}
        </template>

        <template #cell(code)="data">
          {{ data.item.ext.code }}
        </template>

        <template #cell(create_time)="data">
          {{ toDate(data.item.create_time) }}
        </template>

        <template #cell(purchase_team_id)="data">
          {{ getCodeLabel('purchase_team', data.item.purchase_team_id) }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--单菜单-->
              <b-link>
                <feather-icon @click="delOrderItem(data.item)"
                              icon="XSquareIcon"
                              size="16"
                              class="align-middle text-body"
                />
              </b-link>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-warehouserentorder-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-warehouserentorder-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import warehouserentorderUseList from './warehouserentorderUseList'
import warehouserentorderStore from './warehouserentorderStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import Ripple from "vue-ripple-directive";
import {useRouter} from "@core/utils/utils";
import axios from "@/libs/axios";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    XyInputButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {key: 'stock_id', label: 'stockId'},
        {key: 'batch_no', label: '批次'},
        {key: 'warehouse_name', label: '历史仓库'},
        {key: 'in_time', label: '计费开始时间'},
        {key: 'out_time', label: '计费终止时间'},
        {key: 'days', label: '在库周期(天)'},
        {key: 'qty', label: '在库数量'},
        {key: 'rule', label: '托规'},
        // {key: 'rule_qty', label: '在库托数'},
        {key: 'rent_price', label: '租金(元)'},
        {key: 'rent_rule', label: '计费规则'},
      ],
      advanced_search_modal: false,
    }
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('warehouserentorder/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    resetCondition() {
      this.condition = {}
      this.refetchData();
    },
    searchByCondition() {
      this.refetchData();
    },
  },
  watch: {
    $route(to, from) {
      this.routeType = this.$route.query.type
    },
  },
  setup() {
    const router = useRouter()
    const toast = useToast()
    // Register module
    if (!store.hasModule('warehouserentorder')) store.registerModule('warehouserentorder', warehouserentorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehouserentorder')) store.unregisterModule('warehouserentorder')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const createExcel = function () {
      axios({
            url: '/api/exportexcelfile/createWarehouseRentOrderExcel',
            params:{
              code:condition.value.code,
              name:condition.value.name,
              companyId:condition.value.companyId,
              warehouseId:condition.value.warehouseId,
              addTime:condition.value.addTime,
              type:routeType.value,
              stockId:condition.value.stockId,
              purchaseTeamId:isEmpty(condition.value.purchaseTeamId)?null:condition.value.purchaseTeamId.value,
            },
            method:'get',
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "stock-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const {
      condition,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      totalAmount,
      routeType,
      sumTotalAmount,

      // UI
    } = warehouserentorderUseList(
        {
      type: router.route.value.query.type
    })

    return {
      condition,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      totalAmount,
      sumTotalAmount,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      // UI
      toTime,
      toDate,
      advanced_search,
      fromChildren,
      routeType,
      createExcel,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
